var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.list, function (item, i) {
      return _c(
        "b-dropdown-item",
        { key: i, attrs: { to: item.routerTo } },
        [
          _c("font-awesome-icon", { attrs: { icon: item.icon } }),
          _vm._v(" "),
          _c("span", { domProps: { textContent: _vm._s(item.menuName) } }, [
            _vm._v("menuName"),
          ]),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }